.mobile-menu {
	overflow: scroll;
	width: 100vw;
	height: 100vh;
	background-color: $color-red-dark;
	text-align: center;
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	transition: 1s;
	&.closed {
		left: 150vw;
	}

	.navs {
		//position: relative;
		&.portrait {
			margin-top: 10%;
		}

		&.landscape {
			padding-bottom: 200px;
			#mobile-languages {
				position: initial;
				padding-top: 60px;
			}
		}

		vertical-align: middle;
		a {
			color: #fff;
			text-decoration: none;

			font-family: "Open Sans";
			font-size: 22px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: center;
			text-transform: uppercase;
			padding-top: 69px;
			&:last-child {
				padding-bottom: 50px;
			}
		}
	}

	#mobile-languages {
		position: absolute;
		bottom: 85px;
		text-align: center;
		width: 100vw;
		color: #fff;
		text-transform: uppercase;
		font-family: "Open Sans";
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		.active {
			font-weight: 700;
		}
		span:nth-child(2) {
			margin-left: 20px;
		}
	}
}

.mobile-menu-show {
	// height: 64px;
	@media (min-width: 992px) {
		display: none;
	}
}

#menu {
	.dropdown {
		align-self: center;
		@media (max-width: 991px) {
			display: none;
		}

		#dropdown-language {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			color: $color-red-dark;
			border: none;
			font-family: "Open Sans";
			font-size: 12px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			text-transform: uppercase;
			&::after {
				margin: 0;
				border: none;
				background: url("https://amrestcdn.azureedge.net/ph-web-ordering/franchise-pl/home/icons/chevron-red.svg") no-repeat center !important;
				height: 24px;
				width: 24px;
			}
			&:active,
			&:focus,
			&:focus-visible {
				border: none;
				outline: none;
				box-shadow: none;
			}
		}

		[aria-labelledby="dropdown-language"] {
			@media (max-width: 1230px) {
				left: -200%;
			}
			border: none;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
			width: 120px;
			height: 100px;
			padding-left: 27px;
			padding-top: 16px;
			margin-top: 10px !important;
			left: -100%;

			a {
				padding: 0;
				padding-bottom: 10px;
				&:hover {
					background-color: #fff;
				}

				color: #000000;
				font-family: "Open Sans";
				font-size: 12px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: normal;
				line-height: 30px;
				text-align: left;
				text-transform: uppercase;
				font-style: normal;
				letter-spacing: normal;
				line-height: normal;

				&.active {
					color: #c8102e;
					font-weight: 700;
					font-style: normal;
					letter-spacing: normal;
					line-height: normal;
					background-color: #fff;
				}
			}
		}
	}
}

.firstS-container {
	h2 {
		margin-top: 20px;
		margin-bottom: 20px;
		@media (min-width: 768px) {
			margin-top: 60px;
			margin-bottom: 70px;
		}

		color: $color-red-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 800;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.leftSide {
		img {
			max-width: 100%;
			width: 100%;

			@media (max-width: 768px) {
				padding-right: 18px;
			}
		}
	}

	.rightSide {
		@media (min-width: 901px) {
			padding-left: 115px;
		}
		@media (max-width: 900px) {
			padding: 20px;
		}

		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 18px;
		}

		p {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin-top: 50px;
			margin-bottom: 50px;
			position: relative;

			&:before {
				content: "";
				height: 120%;
				top: -10%;
				left: 50px;
				width: 1px;
				background-color: $color-red-dark;
				display: block;
				position: absolute;
			}
		}
	}
}

.secondtS-container {
	padding: 24px;
	padding-top: 0;
	@media (min-width: 1024px) {
		padding-top: 90px;
	}

	img {
		align-self: flex-start;
		max-width: 100%;
		width: 100%;
	}

	.counter-1,
	.counter-2 {
		@media (max-width: 1024px) {
			padding-left: 0;
		}

		@media (max-width: 345px) {
			padding-left: 20px;
		}

		p {
			@media (min-width: 769px) and (max-width: 899px) {
				font-size: 16px;
			}
			color: #000000;
			font-family: "Open Sans";
			font-size: 22px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 0px;

			.color {
				display: inline-block;
				color: $color-red-dark;
			}
		}

		.grow {
			@media (min-width: 768px) and (max-width: 1130px) {
				font-size: 60px;
			}
			color: $color-red-dark;
			font-family: "Open Sans";
			font-size: 88px;
			line-height: 88px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
		}
	}

	.counter-2 {
		@media (min-width: 769px) {
			border-left: 1px solid #b8b8b8;
			border-right: 1px solid #b8b8b8;
		}

		@media (max-width: 768px) {
			border-top: 1px solid #b8b8b8;
			border-bottom: 1px solid #b8b8b8;
		}
	}

	.counter {
		display: flex;
		padding-top: 24px;
		@media (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 30px;
		}
	}

	.mobile-block {
		@media (max-width: 768px) {
			display: block;
		}
	}
}

.thirdS-container {
	padding-top: 90px;
	@media (max-width: 1024px) {
		padding-top: 24px;
	}

	h2 {
		margin-top: 20px;
		margin-bottom: 20px;
		@media (min-width: 768px) {
			margin-top: 60px;
			margin-bottom: 70px;
		}

		color: $color-red-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 800;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.order-md-1 {
		@media (max-width: 1024px) {
			padding-left: 24px;
			padding-right: 24px;
		}
	}

	.leftSide {
		overflow: hidden;

		img {
			max-width: 100%;
			width: 100%;

			@media (max-width: 768px) {
				position: relative;
				left: 18px;
			}
		}
	}

	.rightSide {
		padding: 24px;
		@media (min-width: 900px) {
			padding-right: 96px;
		}

		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 18px;
		}

		p {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin-top: 38px;
			margin-bottom: 38px;
			position: relative;

			.globe {
				position: absolute;
				top: calc(50% - 16px);
				left: 20px;
			}
		}
	}
}

.forthS-container {
	@media (max-width: 900px) {
		margin-top: 24px;
		margin-bottom: 24px;
	}
	margin-top: 90px;
	margin-bottom: 90px;

	img {
		max-width: 100%;
		width: 100%;
	}

	.counter-1 {
		&:not(:last-child) {
			@media (min-width: 769px) {
				border-right: 1px solid #b8b8b8;
			}
		}

		p {
			@media (min-width: 769px) and (max-width: 899px) {
				font-size: 16px;
			}
			color: #000000;
			font-family: "Open Sans";
			font-size: 22px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 0px;

			.color {
				display: inline-block;
				color: $color-red-dark;
			}
		}

		.grow {
			@media (min-width: 768px) and (max-width: 1130px) {
				font-size: 60px;
			}
			color: $color-red-dark;
			font-family: "Open Sans";
			font-size: 88px;
			line-height: 88px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
		}
	}

	.counter {
		@media (max-width: 768px) {
			padding-top: 25px;
			padding-bottom: 30px;
			padding-left: 24px;
			&:not(:last-child) {
				border-bottom: 1px solid #b8b8b8;
			}
		}
		padding-right: 10px;
		padding-left: 10px;

		&.c-2 {
			.p2 {
				text-align: right;
			}

			.centerme {
				width: max-content;
				margin: 0 auto;
			}
		}

		&.c-4 {
			@media (max-width: 767px) {
				.h1 {
					padding-left: 75px;
				}
				.h2 {
					display: inline-block;
					width: 100%;
					padding-left: 75px;

					.block {
						display: inline;
					}
				}
			}
		}
	}

	.block {
		display: block;
	}

	.half {
		width: max-content;
		display: inline-block;
	}
}

.fifthS-container {
	h2 {
		padding-top: 60px;
		margin-top: 20px;
		margin-bottom: 20px;
		@media (min-width: 768px) {
			margin-top: 60px;
			margin-bottom: 70px;
		}

		color: $color-red-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 800;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.leftSide {
		img {
			max-width: 100%;
			width: 100%;
		}
	}

	.rightSide {
		padding: 24px;
		@media (min-width: 900px) {
			padding-right: 96px;
		}

		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 18px;
		}

		p {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin: 32px 0;
			@media (max-width: 768px) {
				padding-left: 52px;
			}
			position: relative;

			&:before {
				content: "";
				height: 120%;
				top: -10%;
				left: 50px;
				width: 1px;
				background-color: $color-red-dark;
				display: block;
				position: absolute;
				@media (max-width: 768px) {
					left: 32px;
				}
			}
		}
	}
}

.sixthS-container {
	margin-top: 90px;
	padding: 24px;
	@media (max-width: 900px) {
		margin-top: 0;
		padding-left: 0;
	}

	img {
		max-width: 100%;
		width: 100%;
	}

	.counter-1,
	.counter-2 {
		p {
			@media (min-width: 769px) and (max-width: 899px) {
				font-size: 16px;
			}
			color: #000000;
			font-family: "Open Sans";
			font-size: 22px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 0px;

			.color {
				display: inline-block;
				color: $color-red-dark;
			}
		}

		.grow {
			color: $color-red-dark;
			font-family: "Open Sans";
			font-size: 88px;
			line-height: 88px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;

			@media (max-width: 768px) {
				font-size: 9.9rem;
			}
		}
	}

	.counter-2 {
		@media (min-width: 769px) {
			border-left: 1px solid #b8b8b8;
			border-right: 1px solid #b8b8b8;
		}

		@media (max-width: 768px) {
			border-bottom: 1px solid #b8b8b8;
		}
	}
	.counter {
		@media (max-width: 768px) {
			padding-top: 25px;
			padding-bottom: 30px;
			border-bottom: 1px solid #b8b8b8 !important;
		}
	}
	.counter-3 {
		border: none !important;
	}

	.mobile-block {
		@media (max-width: 768px) {
			display: block;
		}
	}
}

.bullets-container {
	.benefits-bullets {
		margin-top: 80px !important;
		max-width: 684px;
		margin: 0 auto;

		h2 {
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			text-transform: none;
		}

		h3 {
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
		}

		.bullet-item {
			padding-left: initial !important;
		}
	}
}

.seventhS-container {
	margin-top: 90px;
	@media (max-width: 900px) {
		margin-top: 0;
	}
	h4 {
		font-weight: bold;
		padding-bottom: 30px;
		padding-top: 30px;
	}
	h2 {
		margin-top: 60px;
		margin-bottom: 40px;
		@media (min-width: 768px) {
			margin-top: 20px;
			margin-bottom: 70px;
		}

		color: $color-red-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 800;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.leftSide {
		overflow: hidden;

		img {
			max-width: 100%;
			width: 100%;

			@media (max-width: 768px) {
				position: relative;
				right: 0;
			}
		}
	}

	.rightSide {
		padding: 24px;
		@media (min-width: 900px) {
			padding-right: 96px;
		}

		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		p {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin-top: 38px;
			margin-bottom: 38px;
			position: relative;

			.globe {
				position: absolute;
				top: calc(50% - 16px);
				left: 20px;
			}
		}
	}
}

.eighthS-container {
	@media (min-width: 1200px) {
		margin: 90px auto;
	}

	@media (max-width: 1199px) {
		margin: 24px 0;
	}

	.grow-1 {
		padding-left: 94px;
		@media (max-width: 768px) {
			text-align: center !important;
			padding-left: 0;
			font-size: 99px !important;
		}
	}

	.grow-1-caption {
		text-align: right !important;
		@media (max-width: 768px) {
			text-align: center !important;
			padding-left: 40px;
		}
	}

	.grow-2-caption {
		text-align: right !important;
		@media (max-width: 768px) {
			text-align: left !important;
			padding-left: 95px;
		}
	}

	.block {
		display: block !important;
		line-height: 22px;
	}

	img {
		max-width: 100%;
		width: 100%;
	}

	.counter-1,
	.counter-2 {
		@media (max-width: 768px) {
			border-top: 1px solid #b8b8b8;
		}
		p {
			@media (min-width: 769px) and (max-width: 899px) {
				font-size: 16px;
			}
			color: #000000;
			font-family: "Open Sans";
			font-size: 22px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 0px;

			.color {
				display: inline-block;
				color: $color-red-dark;
			}
		}

		.grow {
			@media (max-width: 1200px) {
				font-size: 60px;
			}

			@media (max-width: 400px) {
				font-size: 50px;
			}
			color: $color-red-dark;
			font-family: "Open Sans";
			font-size: 88px;
			line-height: 88px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
		}
	}

	.counter-2 {
		@media (min-width: 769px) {
			border-left: 1px solid #b8b8b8;
		}
	}

	.counter {
		@media (max-width: 768px) {
			padding: 16px 24px;
		}
	}

	.mobile-block {
		@media (max-width: 768px) {
			display: block;
		}
	}
}

.ninethS-container {
	padding-bottom: 90px;
	@media (max-width: 900px) {
		padding-bottom: 24px;
	}

	h2 {
		margin-top: 60px;
		margin-bottom: 30px;
		@media (min-width: 768px) {
			margin-top: 120px;
			margin-bottom: 70px;
		}

		color: $color-red-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 800;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.leftSide {
		img {
			max-width: 100%;
			width: 100%;

			@media (max-width: 768px) {
				padding-left: 18px;
			}
		}
	}

	.rightSide {
		padding-right: 16px;
		@media (min-width: 769px) {
			padding-left: 54px;
		}

		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 18px;
		}

		p {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 800;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin-top: 50px;
			margin-bottom: 50px;
			position: relative;

			&:before {
				content: "";
				height: 120%;
				top: -10%;
				left: 50px;
				width: 1px;
				background-color: $color-red-dark;
				display: block;
				position: absolute;
			}
		}
	}

	.rightSide {
		.bullets-container {
			padding: 0px;

			.benefits-bullets {
				margin-top: 0px !important;

				h3 {
					font-size: 16px !important;
				}
			}
		}
	}

	.quote {
		margin-bottom: 45px;

		.marks {
			display: inline-block;
			width: 10%;
			font-family: "Open Sans";
			font-size: 85px;
			font-style: italic;
			letter-spacing: normal;
			line-height: 32px;
			text-align: left;
			vertical-align: top;
			position: relative;
			left: -10px;
		}

		.marks2 {
			font-family: "Open Sans";
			font-size: 85px;
			font-style: italic;
			letter-spacing: normal;
			line-height: 32px;
			text-align: left;
			vertical-align: bottom;

			display: inline-block;
			position: relative;
			bottom: -30px;

			@media (min-width: 1134px) {
				left: 390px;
				bottom: 0px;
			}
		}

		.caption {
			display: inline-block;
			width: 89%;
			font-family: "Open Sans";
			font-size: 28px;
			font-style: italic;
			letter-spacing: normal;
			line-height: 32px;
			text-align: left;
		}
	}
}

.tenthS-container {
	h2 {
		margin-top: 70px;
		color: #c8102e;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 800;
		font-style: normal;
		letter-spacing: normal;
		line-height: 36px;
		text-align: center;
	}

	img {
		width: 100%;
		@media (max-width: 768px) {
			padding: 18px;
		}
	}
}

.centerme {
	@media (min-width: 769px) {
		width: max-content;
		margin: 0 auto;
	}
}

.contact-button {
	width: 300px;
	height: 60px;
	background-color: $color-red-dark;

	color: #ffffff;
	font-family: "Open Sans";
	font-size: 16px;
	line-height: 60px;
	font-weight: 800;
	font-style: normal;
	letter-spacing: normal;
	text-align: center;
	margin: 0 auto;
	display: block;
	text-decoration: none;

	&:hover {
		color: #fff;
	}

	@media (max-width: 768px) {
		margin: 0 auto;
		width: 343px;
	}
	@media (max-width: 480px) {
		width: 100%;
	}
}

.forth-section-button {
	margin-top: 30px;
	position: relative;
	top: 30px;
}

.no-border {
	border: none !important;
}

.pull-this-button-down {
	top: 60px;
	@media (max-width: 768px) {
		top: 30px !important;
	}
}

video {
	width: 100%;
	padding-bottom: 18px;
	@media (max-width: 1200px) {
		width: 100vw;
	}
}

.amrest-wrapper {
	padding-bottom: 90px;
	background-color: $color-gray;
	@media (max-width: 1200px) {
		padding-bottom: 16px;
	}
	.rightSide {
		padding-left: 24px;
		padding-right: 24px;
	}
}

body.en {
	.counter-1:nth-child(2) {
		.grow {
			font-size: 62px;
			@media (max-width: 1080px) {
				font-size: 5vw;
			}
			@media (max-width: 767px) {
				font-size: 62px;
			}
		}
	}

	.counter-1:nth-child(4) {
		.grow {
			@media (max-width: 1240px) and (min-width: 768px) {
				font-size: 4.75vw;
			}
		}

		.h2 {
			@media (max-width: 1240px) and (min-width: 768px) {
				font-size: 1.75vw;
			}
		}
	}

	.counter-1:nth-child(3) {
		p {
			@media (max-width: 1240px) and (min-width: 768px) {
				font-size: 2vw;
			}
		}
	}

	.single-advantage {
		@media (max-width: 767px) {
			&:nth-child(1) {
				p {
					max-width: 20% !important;
				}
			}
		}
		@media (max-width: 681px) {
			&:nth-child(1) {
				p {
					max-width: 30% !important;
				}
			}
		}
		@media (max-width: 524px) {
			&:nth-child(1) {
				p {
					max-width: 40% !important;
				}
			}
		}

		@media (max-width: 767px) and (min-width: 480px) {
			&:nth-child(2) {
				p {
					max-width: 40% !important;
				}
			}
		}
	}
}

.single-advantage {
	@media (min-width: 551px) and (max-width: 699px) {
		&:nth-child(1) {
			p {
				max-width: 35% !important;
			}
		}
	}
	@media (min-width: 377px) and (max-width: 550px) {
		&:nth-child(1) {
			p {
				max-width: 45% !important;
			}
		}
	}
	@media (min-width: 700px) and (max-width: 767px) {
		&:nth-child(1) {
			p {
				max-width: 30% !important;
			}
		}
	}
	@media (min-width: 532px) and (max-width: 767px) {
		&:nth-child(2) {
			p {
				max-width: 50% !important;
			}
		}
	}
	@media (min-width: 723px) and (max-width: 767px) {
		&:nth-child(2) {
			p {
				max-width: 40% !important;
			}
		}
	}
}

@media (min-width: 1134px) and (max-width: 1200px) {
	.ninethS-container .quote {
		position: relative;
		left: -40px;
	}
}

@media screen and (max-width: 900px) {
	.full-img {
		padding-left: 0;
		padding-right: 0;
	}
}
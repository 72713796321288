@import "scss/config/variables";
@import "scss/config/base";
@import "scss/components/benefits";
@import "scss/components/process";
@import "scss/components/menu";

.col-md-6 {
	@media only screen and (max-width: 840px) {
		width: 100%;
	}
}
.mobile-block {
	display: flex;
}
#app-container {
	background-color: $color-white;
}
.navbar-container {
	min-height: 64px;
	align-items: stretch !important;
}
.navbar-collapse {
	align-items: stretch;
}
.navbar-brand {
	display: flex;
	align-self: center;
	span {
		color: $color-black;
		font-size: 18px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: left;
		text-transform: uppercase;
	}
}

#menu {
	padding: 0;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

	.menu-link {
		position: relative;
		color: #000;
		padding: 24px 12px;
		text-decoration: none;
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: left;
		text-transform: uppercase;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		z-index: 10;
		transition: all 0.2s;

		&::after {
			content: "";
			position: absolute;
			width: 100%;
			background-color: transparent;
			height: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			transition: all 0.2s;
		}

		&:hover {
			color: $color-white !important;
			&::after {
				height: 100%;
				background-color: $color-red-dark;
			}
		}

		&.menu-link-selected {
			color: $color-red;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				width: 100%;
				background-color: $color-red-dark;
				height: 5px;
				right: 0;
				bottom: 0;
				z-index: -1;
				transition: all 0.2s;
			}

			&:hover {
				color: $color-white !important;
				&::after {
					height: 100%;
				}
			}
		}
	}

	.navbar-brand span {
		position: relative;
		top: 6px;
		left: 10px;

		@media (max-width: 374px) {
			font-size: 16px;
		}
	}
}

.ph-text-input {
	width: 500px;
	max-width: 90%;
	margin: 0 auto;

	.text-muted {
		color: $color-red-dark !important;
		font-size: 10px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 16px;
	}

	.form-label {
		color: #7a858d !important;
		font-weight: 700;
		text-transform: uppercase;
		max-width: 100%;
		float: left;
		margin-bottom: 8px !important;
	}
}

.uneditable-input:focus,
.form-control:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.uneditable-input:active,
.form-control:active,
.form-control.is-invalid:active,
.was-validated .form-control:invalid:active {
	border-color: #dfdfe3 !important;
	box-shadow: none !important;
	outline: 0 none;
	border-radius: 5px !important;
	background-color: $color-white;
}

.form-control {
	border-color: #dfdfe3;
	background-color: $color-gray;
	border-radius: 5px;
	transition: all 0.2s;
}
.form-title {
	width: 100%;
	@media (max-width: 767px) {
		width: 80%;
		margin: 0 auto;
	}
}
.form-label {
	color: $color-black;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: 16px;
	text-align: left;
	/* Text style for "Applicants" */
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid,
.was-validated .form-control:invalid {
	border-color: $color-red-dark;
	background-image: none !important;
}

.ph-dropdown-button {
	width: 500px;
	height: 50px;
	border-radius: 5px;
	border: 1px solid #ced4da;
	color: #b5b6bd !important;

	@media (max-width: 767px) {
		width: 100%;
	}

	button,
	button:active,
	button:hover,
	button:focus {
		background-color: $color-white !important;
		border: none;
		border-radius: 0px;
		border-radius: 5px;
		color: #b5b6bd !important;
		text-align: left;
		outline: 0 none !important;
		box-shadow: none !important;
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		padding-left: 24px;

		&:after {
			position: absolute;
			right: 10px;
			top: 45%;
		}
	}

	button {
		background-color: $color-gray !important;
	}

	.dropdown-item {
		padding-left: 24px;
		display: flex;
		align-items: center;
		width: 500px;
		color: #231f20;
		font-size: 16px;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		height: 50px;

		&:not(:last-child) {
			border-bottom: 1px solid #eeeeee;
		}

		&:hover {
			background-color: $color-gray !important;
		}
	}

	a {
		&:active {
			background-color: #eeeeee !important;
			color: #000;
		}
	}

	div {
		max-width: 100%;
		overflow: hidden;
	}
}

#form-page {
	padding-top: 40px;
}

.ph-colapsable-section {
	max-width: 548px !important;
	padding: 0;
	margin-top: 25px;
	margin-bottom: 25px;
	text-align: center;
	border: 1px solid #dfdfe3;

	.flexbox {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 600;
	}
	.header {
		margin: 0;
	}
	.icon {
		height: 32px;
		width: 32px;
	}
	h2 {
		background-color: $color-red-dark;

		a {
			color: $color-white;
			padding: 24px;
			text-align: left;
			display: inline-block;
			text-decoration: none;

			.col-3 {
				text-align: center;

				svg {
					font-size: 40px;
					margin-top: calc(50% - 30px);
				}
			}
		}
	}
}

.check-mark {
	width: 20px;
	height: 20px;
	border: 1px solid #dfdfe3;
	cursor: pointer;
	text-align: center;
	color: #dfdfe3;
}

.error-check-mark {
	border: 1px solid $color-red-dark;
}

.ph-checkbox {
	width: 400px;
	max-width: 90%;
	padding-top: 30px !important;
	margin: 0 auto !important;

	.row {
		width: 400px;
		max-width: 100%;
	}
}

.pa-checkbox-label {
	color: $color-black;
	font-size: 12px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
}

.ph-submit-button {
	width: 339px;
	max-width: 90%;
	height: 60px;
	color: $color-white;
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
	text-align: center;
	background-color: $color-red-dark;
	border: none;
	margin-bottom: 50px;
	margin-top: 50px;
	&:hover {
		background-color: $color-red;
	}
	@media (max-width: 767px) {
		width: 100%;
	}
}

.star {
	color: $color-red-dark;
}

.ph-file-button {
	width: 492px;
	max-width: 100%;
	height: 40px;
	font-size: 16px;
	font-weight: 600;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
	text-align: center;
	background-color: #e9ebee;
	border-radius: 5px;
	border: 1px solid #ced4da;
	color: #231f20 !important;
	transition: all 0.2s;
	margin-bottom: 8px;

	&:hover {
		color: $color-gray;
		background-color: $color-white;
	}
}

.ph-file-span {
	color: #7a858d;
}

#breadcrumbs {
	border: 1px solid #eeeeee;
	color: #bbbbbb;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
	padding-left: 24px;
	padding-bottom: 14px;
	padding-top: 17px;
}

.thank-you {
	font-weight: 700;
	font-style: normal;
	letter-spacing: normal;
	text-align: center;
	font-size: 20px;
	margin-bottom: 50px;
}

.container {
	max-width: 1124px;
}

.faq-container {
	background-color: $color-red-dark;
	padding-top: 42px;
	padding-bottom: 116px;

	h2 {
		@media (max-width: 767px) {
			background-color: #fff;
			color: $color-red-dark;
			width: 100vw;
			position: relative;
			left: -15px;
			top: -55px;
		}
		color: $color-white;

		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
		text-transform: uppercase;
		/* Text style for "FAQ" */
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
	}

	a.button {
		width: 300px;
		height: 60px;
		color: #c8102e;
		display: block;
		margin: 60px auto 0;
		position: relative;
		top: 30px;
		background-color: $color-white;

		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: center;
		line-height: 60px;
		text-decoration: none;
		max-width: 100%;
	}

	.mobile-img {
		display: none;
		width: 100vw;
		position: relative;
		left: -15px;
		top: -76px;

		@media (max-width: 767px) {
			display: block;
		}
	}

	ul {
		list-style: none;

		li {
			display: block;

			&:not(:first-child) {
				padding-top: 40px;
			}

			&:not(:last-child) {
				border-bottom: 1px solid rgba(255, 255, 255, 0.3);
				padding-bottom: 32px;
			}

			button {
				background: none;
				border: none;

				img {
					width: 20px;
					transition: 0.3s;

					&.open {
						transform: rotate(180deg);
					}
				}
			}

			.row {
				div {
					color: $color-white;

					font-size: 16px;
					font-weight: 700;
					font-style: normal;
					letter-spacing: normal;
					line-height: normal;
					text-align: left;
				}
			}

			.collapse,
			.collapsing {
				padding-top: 40px;
				color: $color-white;

				font-size: 16px;
				font-weight: 400;
				font-style: normal;
				letter-spacing: normal;
				line-height: normal;
				text-align: left;
			}
		}
	}
}

.bullets-container {
	h2 {
		@media (max-width: 560px) {
			text-align: left;
			padding-left: 10px;
			span {
				display: block;
			}
		}
		color: $color-black;

		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: center;
		text-transform: uppercase;
	}

	.bullet-item {
		padding-bottom: 27px;
		@media (max-width: 1024px) {
			padding-left: calc(50vw - 320px);
		}

		@media (max-width: 525px) {
			padding-left: 3vw;
		}
		@media (max-width: 295px) {
			padding-left: 20px;
		}
		padding-left: 236px;

		img {
			width: 17px;
		}

		.bullet-item-text {
			color: $color-black;

			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
		}
	}
}

.slider-container {
	margin-top: 30px;
	max-width: 1210px;

	@media (max-width: 768px) {
		margin-top: 0px;
	}

	.slider-caption {
		max-width: 90vw;
		position: absolute;
		bottom: -25px;
		left: -16px;
		width: 320px;
		height: 246px;
		background-color: #fff;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
		padding: 24px 28px;

		&.with-pdf {
			width: 350px;
		}

		@media (max-width: 500px) {
			padding: 20px;
			//padding-right: 0px;
		}

		@media (max-width: 767px) {
			width: 384px;
			&.with-pdf {
				width: 384px;
			}
			bottom: -155px;
			left: initial;
			&.no-white {
				bottom: -20px;
			}
		}

		h2 {
			color: $color-red-dark;

			font-size: 28px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: 36px;
			text-align: left;
			margin: 0;
			@media (max-width: 767px) {
				font-size: 32px;
				width: calc(100% + 20px);
			}

			@media (max-width: 424px) {
				font-size: 26px;
			}
		}

		p {
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-top: 10px;
			padding-bottom: 0px;
		}

		a {
			display: block;
			width: 317px;
			height: 60px;
			background-color: $color-red-dark;
			width: 240px;

			max-width: 100%;
			color: $color-white;

			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: 60px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			@media (max-width: 768px) {
				width: 317px;
				margin: 0 auto;
			}
		}
	}

	.carousel.slide {
		width: 1200px;
		max-width: 100%;
		transition: 1s;
		margin-bottom: 70px;
		@media (min-width: 768px) {
			height: 710px;
		}

		.carousel-inner {
			@media (max-width: 768px) {
				transition: 1s;
				padding-bottom: 100px;
			}

			.slide-inner-wrapper {
				background-position: center;
				background-repeat: no-repeat;
				width: 1124px;
				max-width: 100%;

				@media (max-width: 768px) {
					transition: 1s;
					height: 600px;
					margin-bottom: 155px;
				}

				&:not(.not-fixed) {
					@media (min-width: 768px) {
						height: 590px;
					}
				}

				margin: 0 auto;
				position: relative;
			}
		}
	}

	.carousel-item {
		@media (min-width: 768px) {
			height: 660px;
		}
	}

	.carousel-indicators {
		transition: 0.3s;

		@media (min-width: 768px) {
			top: 645px;
		}

		@media (min-width: 967px) {
			//top: 700px;
			top: 615px;
		}

		li {
			width: 20px;
			height: 20px;
			border: 3px solid #eeeeee;
			background-color: $color-white;
			border-radius: 50%;
			margin: 7px;
			bottom: 45px;

			&.active {
				background-color: $color-red-dark;
			}
		}
	}

	.slider-left {
		display: inline-block;
		width: 388px;
		height: 565px;
		max-width: 34vw;
		background-position: center;
		background-repeat: no-repeat;

		@media (max-width: 768px) {
			width: 100vw;
			max-width: 100vw;
			background-size: cover;
			height: calc(668px - 565px);
		}
	}

	.slider-right {
		display: inline-block;
		width: 736px;
		height: 565px;
		max-width: 64vw;
		background-position: center;
		background-repeat: no-repeat;

		@media (max-width: 768px) {
			width: 100vw;
			max-width: 100vw;
		}
	}

	.name {
		position: absolute;
		right: 28px;
		top: 34px;
		width: 140px;
		text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
		color: $color-white;

		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: left;

		@media (max-width: 768px) {
			right: 10px;
			top: 24px;
		}

		.position {
			text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
			color: $color-white;

			font-size: 12px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: 16px;
			text-align: left;
		}
	}
}

.quotee {
	display: inline-block;
	position: absolute;
	bottom: 40px;
	right: 28px;
	width: 323px;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
	color: $color-white;
	font-size: 20px;
	font-style: italic;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;

	@media (max-width: 768px) {
		bottom: 100px;
	}

	@media (max-width: 340px) {
		right: -3px;
		font-size: 18px;
	}

	.mark-sign {
		display: inline-block;
		/* Style for "“" */
		width: 14px;
		height: 54px;
		text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
		color: $color-white;

		font-size: 40px;
		font-style: italic;
		letter-spacing: normal;
		line-height: normal;
		text-align: left;
		position: absolute;
		left: -23px;
		top: -20px;
	}
}

.advantages-container {
	margin-top: 50px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 1) 180px, #eee 180px);
	margin-bottom: 30px;

	img {
		max-width: calc(100% + 100px);
		@media (max-width: 768px) {
			max-width: 100vw;
			margin-left: -15px;
		}
	}

	a.button {
		width: 300px;
		height: 60px;
		background-color: $color-red-dark;
		display: block;
		margin: 0 auto;
		position: relative;
		margin: 10px auto 60px;

		color: $color-white;

		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: center;
		line-height: 60px;
		text-decoration: none;
		max-width: 100%;
	}

	h2 {
		color: $color-red-dark;

		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 36px;
		text-align: center;
		margin-bottom: 50px;
	}

	.right-side {
		padding-top: 25px;

		@media (max-width: 768px) {
			padding-top: 0;
		}

		.single-advantage {
			border: 1px solid #eeeeee;
			padding: 28px;
			padding-top: 21px;
			padding-bottom: 21px;
			background-color: #fff;

			@media (max-width: 767px) {
				padding: 18px;
			}

			.the-col {
				position: relative;

				h3 {
					color: $color-black;

					font-size: 22px;
					font-weight: 700;
					font-style: normal;
					letter-spacing: normal;
					line-height: 28px;
					text-align: left;

					img {
						margin-left: 11px;
						position: initial;
					}
				}

				p {
					color: $color-black;

					font-size: 16px;
					font-weight: 400;
					font-style: normal;
					letter-spacing: normal;
					line-height: normal;
					text-align: left;
					max-width: 70%;
					margin-bottom: 0;
				}

				img {
					position: absolute;
					right: 10px;
					top: 25px;
					@media (min-width: 768px) {
						top: 10px;
					}
				}
			}

			a {
				color: #000;
				text-decoration: none;
			}
		}
	}
}

.howto-container {
	padding-top: 55px;
	padding-bottom: 105px;

	h2 {
		margin-bottom: 75px;
		color: $color-red-dark;

		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 36px;
		text-align: center;
	}

	.left-side {
		padding-top: 30px;

		.single-how {
			&:not(:first-child) {
				padding-top: 40px;
			}

			img {
				width: 48px;
				height: 48px;
			}

			h3 {
				@media (min-width: 768px) {
					max-width: 271px;
				}

				color: $color-black;

				font-size: 20px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: normal;
				line-height: 26px;
				text-align: left;
				a {
					text-decoration: none;
					color: $color-black;
				}
			}
		}
	}

	.right-side {
		img {
			max-width: 100%;
			@media (min-width: 1108px) {
				margin-left: 22px;
			}
			@media (max-width: 767px) {
				width: 100vw;
			}
		}
	}

	a.button {
		width: 300px;
		height: 60px;
		background-color: $color-red-dark;
		display: block;
		margin: 0 auto;
		position: relative;
		top: 30px;

		color: $color-white;

		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: center;
		line-height: 60px;
		text-decoration: none;
	}
}

.rodo-container {
	background-color: #eeeeee;
	padding-top: 68px;
	padding-bottom: 65px;

	p {
		color: #b8b8b8;

		font-size: 12px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: left;
	}
}

.footer-container {
	padding-top: 42px;
	padding-bottom: 42px;
	background-color: #000;
	color: #fff;

	.mainLinks {
		a {
			color: $color-white;

			font-size: 12px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			text-transform: uppercase;
			text-decoration: none;

			&:not(:first-child) {
				margin-left: 35px;
			}

			@media (max-width: 768px) {
				display: block;
				text-align: center;
				margin: 0 !important;
				padding-top: 31px;
			}
		}
	}

	.smallLinks {
		padding-top: 35px;

		a {
			color: $color-white;

			font-size: 12px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;

			&:not(:first-child) {
				margin-left: 35px;
			}

			text-decoration: none;

			@media (max-width: 768px) {
				display: block;
				text-align: center;
				margin: 0 !important;
				padding-top: 31px;
			}
		}
	}

	img {
		&:not(:first-child) {
			margin-left: 20px;
		}
	}

	a {
		&:not(:first-child) {
			margin-left: 20px;
		}
	}

	.p-2 {
		p {
			color: $color-white;

			font-size: 12px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}

		@media (max-width: 768px) {
			display: block;
			text-align: center;
			margin: 0 !important;
			padding-top: 31px !important;
		}

		&:first-child {
			margin-left: 90px;
		}
	}

	.footer-addres-info {
		margin-top: 50px;
		border-top: 1px solid #707070;
		p {
			color: $color-white;

			font-size: 12px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			text-align: center;
			padding-top: 40px;
			margin-bottom: 0;
		}
	}
	#ot-sdk-btn.ot-sdk-show-settings,
	#ot-sdk-btn.optanon-show-settings {
		border: none;
		background: none;
		color: #fff;
		font-size: 12px;

		padding: 0px;
		margin-left: 30px;
		&:hover {
			background: none;
		}
	}
}

.hide-desktop {
	@media (min-width: 767px) {
		display: none !important;
	}
	display: initial;
}

.hide-mobile {
	@media (max-width: 767px) {
		display: none !important;
	}
	display: initial;
}

.jumpingText {
	color: $color-black;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
	max-width: 669px;
	margin: 0 auto;
}

.slide-no-1,
.slide-no-0 {
	margin-bottom: 0px !important;
}

.personal-data-inspector {
	width: 669px;
	height: 138px;
	border: 1px solid #b8b8b8;
	margin: auto;
	padding: 35px 50px;
	margin-bottom: 50px;

	@media (max-width: 768px) {
		width: calc(100% - 40px);
		margin-left: 20px;
		margin-right: 20px;
		padding: 25px 35px;
		height: max-content;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-align: left;
		padding: 0;
		margin: 0;
	}
	a {
		color: #000;
		text-decoration: none;
	}
}

body[show-recaptha="false"] {
	.grecaptcha-badge {
		z-index: -10;
		display: none;
	}
}

@media (max-width: 768px) {
	.advantages-container {
		img.hide-desktop {
			width: calc(100% + 30px);
			margin: 0 -15px;
		}
	}
}

.list-group {
	&-item {
		> img {
			flex-shrink: 0;
		}
	}
}